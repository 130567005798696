import classNames from "classnames";
import React, { forwardRef, memo, useMemo } from "react";
import { Icon } from "../../graphic/Icon";
import styles from "./Item.scss";
import { SortableItem, SortableKnob } from "react-easy-sort";

export const Item = (props) => {
    const { index, sortable } = props;
    return sortable ? (
        <SortableItem key={index}>
            <ItemWrapper {...props} />
        </SortableItem>
    ) : (
        <ItemWrapper {...props} />
    );
};

const ItemWrapper = forwardRef((props, ref) => {
    const { value, prependAlign, appendAlign, onClick, highlighted, render } = props;
    const isHighlighted = useMemo(
        () => (typeof highlighted === "function" ? highlighted(value) : highlighted),
        [highlighted, value]
    );

    const element = (
        <div
            ref={ref}
            className={classNames(styles.item, {
                [styles.clickable]: onClick,
                [styles.prependAlignTop]: prependAlign === "top",
                [styles.appendAlignTop]: appendAlign === "top",
                [styles.isHighlighted]: isHighlighted
            })}
            onClick={() => onClick && onClick(value)}
        >
            <ItemContent {...props} />
            <div className={styles.frame} />
        </div>
    );

    return render ? render(element) : element;
});

const ItemContent = memo(({ prepend, append, sortable, children }) => {
    return (
        <div className={styles.inner}>
            {/* Prepend */}
            {prepend ? (
                <div className={styles.prepend}>
                    <div className={styles.wrapper}>
                        {Array.isArray(prepend)
                            ? prepend.map((elm, index) =>
                                  React.cloneElement(elm, {
                                      key: index
                                  })
                              )
                            : prepend}
                    </div>
                </div>
            ) : null}

            {/* Content */}
            <div className={styles.content}>
                <div className={styles.wrapper}>{children}</div>
            </div>

            {/* Append */}
            {append ? (
                <div className={styles.append}>
                    <div className={styles.wrapper}>
                        {Array.isArray(append)
                            ? append.map((elm, index) =>
                                  React.cloneElement(elm, {
                                      key: index
                                  })
                              )
                            : append}
                    </div>
                </div>
            ) : null}

            {/* Drag handle */}
            {sortable ? (
                <SortableKnob>
                    <div className={styles.handle} onClick={(e) => e.stopPropagation()}>
                        <Icon className={styles.icon} name="dot-matrix" />
                    </div>
                </SortableKnob>
            ) : null}
        </div>
    );
});
