import React from "react";

import { FormattedMessage } from "react-intl";
import { PageMessage } from "../../page/PageMessage";
import Section from "../../section/Section";
import { Text } from "../../typography/Text";
import Paragraph from "../../typography/Paragraph";
import Row from "../../grid/Row";
import Col from "../../grid/Col";
import { Button } from "../../button/Button";
import { ShieldLogo } from "../../graphic/ShieldLogo/ShieldLogo";

const msgKey = "trialExpiredMessage";

export const TrialExpiredMessage = ({ accountName, continueLink }) => {
    return (
        <PageMessage title={<FormattedMessage id={`${msgKey}.trialExpiredTitle`} />} figure={<ShieldLogo size={100} />}>
            <Section>
                <Paragraph>
                    <Text size="xlarge" weight="semiBold" color="error">
                        <FormattedMessage id={`${msgKey}.zeroDaysLeft`} />
                    </Text>
                </Paragraph>
                <Text block size="medium" color="base80">
                    <FormattedMessage
                        id={`${msgKey}.trialExpiredMessage`}
                        values={{ name: accountName, strong: (msg) => <strong>{msg}</strong> }}
                    />
                </Text>
            </Section>
            <Row>
                <Col sm={{ span: 14, offset: 5 }}>
                    <Button fill link={continueLink} color="success" icon="account" size="big">
                        <FormattedMessage id={`${msgKey}.chooseSubscription`} />
                    </Button>
                </Col>
            </Row>
        </PageMessage>
    );
};
