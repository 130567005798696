import React from "react";
import styles from "./PageBar.scss";

export const PageBar = ({ children }) => {
    return (
        <div className={styles.base}>
            <div className={styles.wrapper}>{children}</div>
        </div>
    );
};
