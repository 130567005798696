import React from "react";
import { PhotoProvider } from "react-photo-view";
import { Spinner } from "../../loaders/Spinner";
import "react-photo-view/dist/react-photo-view.css";
import styles from "./Lightbox.scss";
import { Icon } from "../../graphic/Icon";

export const LightboxProvider = ({ children }) => {
    return (
        <PhotoProvider
            loadingElement={<Spinner contrast />}
            brokenElement={<Icon name="error-circle" size="xLarge" color="silent" />}
            overlayRender={Overlay}
        >
            {children}
        </PhotoProvider>
    );
};

const Overlay = ({ overlay }) => {
    return overlay ? <div className={styles.overlay}>{overlay}</div> : null;
};
