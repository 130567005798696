import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { figureList } from "./figureList";
import style from "./style.scss";

/**
 * FIGURE
 *
 * Using this tool to convert icons:
 * https://react-svg-icon-live-generator.herokuapp.com/
 *
 * or this to clean it:
 * https://jakearchibald.github.io/svgomg/
 */

export const Figure = ({ name, color, size, className }) => {
    const element = figureList[name]?.element;
    if (!element) {
        console.warn(`Figure "${name}" not found`);
        return null;
    }
    return React.cloneElement(element, {
        className: classNames(
            style.base,
            {
                [style.colorSilent]: color === "silent",
                [style.colorPrimary]: color === "primary",
                [style.sizeSmall]: size === "small",
                [style.sizeRegular]: size === "regular",
                [style.sizeMedium]: size === "medium",
                [style.sizeLarge]: size === "large"
            },
            className
        )
    });
};

Figure.propTypes = {
    name: PropTypes.oneOf(Object.keys(figureList)).isRequired
};
