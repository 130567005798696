import classNames from "classnames";
import React, { useRef, useState } from "react";
import { useDebounce } from "../../../utils/hooks";
import styles from "./Breadcrumbs.scss";

export const Breadcrumbs = ({ children }) => {
    const [compact, setCompact] = useState(false);
    const baseRef = useRef(null);
    const containerRef = useRef(null);
    const containerFullWidth = useRef(0);

    useDebounce(
        () => {
            const baseElement = baseRef.current;
            const containerElement = containerRef.current;

            containerFullWidth.current = containerElement?.clientWidth + 10;

            const handleResize = () => {
                setCompact(containerFullWidth.current > baseElement.clientWidth);
            };
            const resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(baseElement);

            return () => {
                resizeObserver.disconnect();
            };
        },
        [],
        200,
        false
    );

    return (
        <div
            ref={baseRef}
            className={classNames(styles.base, {
                [styles.compact]: compact
            })}
        >
            <div ref={containerRef} className={styles.container}>
                {children}
            </div>
        </div>
    );
};
