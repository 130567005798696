import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { iconList } from "./iconList";
import style from "./style.scss";

/**
 * APP ICON
 *
 * Using this tool to convert icons:
 * https://react-svg-icon-live-generator.herokuapp.com/
 *
 * or this to clean it:
 * https://jakearchibald.github.io/svgomg/
 */

export const Icon = ({ name, color, size, rotate, filled, circle, marginRight, presentation, className }) => (
    <span
        className={classNames(
            style.base,
            {
                [style.presentation]: presentation,

                [style.colorBase]: color === "base",
                [style.colorBase80]: color === "base80",
                [style.colorBase60]: color === "base60",
                [style.colorBase40]: color === "base40",

                [style.colorInfo]: color === "info",
                [style.colorPrimary]: color === "primary",
                [style.colorSuccess]: color === "success",
                [style.colorWarning]: color === "warning",
                [style.colorError]: color === "error",
                [style.colorContrast]: color === "contrast",
                [style.colorSilent]: color === "silent",

                [style.sizeSmall]: size === "small",
                [style.sizeMedium]: size === "medium",
                [style.sizeLarge]: size === "large",
                [style.sizeXLarge]: size === "xLarge",
                [style.sizeFill]: size === "fill",

                [style.marginRight]: marginRight,

                [style.rotate]: rotate,
                [style.filled]: filled,
                [style.circle]: circle
            },
            className
        )}
    >
        {iconList[name]?.element}
        {presentation ? <span className={style.label}>{name}</span> : null}
    </span>
);

Icon.propTypes = {
    name: PropTypes.oneOf(Object.keys(iconList)).isRequired
};
