import React from "react";
import { PhotoView } from "react-photo-view";

export const LightboxEntry = ({ src, overlay, children }) => {
    return (
        <PhotoView src={src} overlay={overlay}>
            {children}
        </PhotoView>
    );
};
