import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Tree } from "react-arborist";
import { Button } from "../../button/Button";
import { ButtonGroup } from "../../button/ButtonGroup";
import { Clickable } from "../../button/Clickable";
import { Figure } from "../../graphic/Figure";
import { Icon } from "../../graphic/Icon";
import { Controls } from "./parts/Controls";
import styles from "./TreeMenu.scss";

export const TreeMenu = React.memo(
    ({
        items,
        selectedId,
        onSelect,
        onEdit,
        onCreate,
        canEditNode,
        onMove,
        renderIcon,
        maxLevels = 4,
        editable = true,
        embedded = false,
        emptyMessage
    }) => {
        const baseRef = useRef();
        const containerRef = useRef();
        const treeRef = useRef();
        const [editMode, setEditMode] = useState(false);
        const [containerHeight, setContainerHeight] = useState(500);
        const [treeHeight, setTreeHeight] = useState(null);

        useEffect(() => {
            // This observer
            const observer = new ResizeObserver((entries) => {
                const height = entries[0].contentRect.height;
                setContainerHeight(height);
            });
            // This tree has the correct total height calculated so we want to watch it using ResizeObserver
            let innerTreeElement;
            let pollId;
            const poll = () => {
                pollId = setTimeout(() => {
                    innerTreeElement = containerRef.current?.firstChild?.firstChild?.firstChild;
                    if (innerTreeElement) {
                        clearTimeout(pollId);
                        observer.observe(innerTreeElement);
                    } else {
                        pollId = poll();
                    }
                }, 30);
            };
            poll();
            return () => {
                observer.disconnect();
                clearTimeout(pollId);
            };
        }, []);

        useEffect(() => {
            const resizeContainer = () => {
                setTreeHeight(containerHeight);
            };

            resizeContainer();
            window.addEventListener("resize", resizeContainer);
            return () => window.removeEventListener("resize", resizeContainer);
        }, [containerHeight]);

        return (
            <div ref={baseRef} className={classNames(styles.base, { [styles.embedded]: embedded })}>
                <Controls tree={treeRef?.current} editable={editable} editMode={editMode} setEditMode={setEditMode} />

                {/* Adder */}
                {editMode && <Adder onClick={() => onCreate()} />}

                {!items?.length && !editMode && editable && (
                    <div className={styles.empty}>
                        <div className={styles.arrow}>
                            <Figure name="arrow-doodle" />
                        </div>
                        <div className={styles.text}>{emptyMessage}</div>
                    </div>
                )}
                <div ref={containerRef} className={styles.container}>
                    <Tree
                        ref={treeRef}
                        width="100%"
                        height={treeHeight}
                        rowHeight={40}
                        data={items}
                        onActivate={onSelect}
                        onMove={onMove}
                        selection={selectedId?.toString()}
                        disableMultiSelection
                        disableEdit
                        disableDrag
                        disableDrop
                    >
                        {(props) => (
                            <Item
                                renderIcon={renderIcon}
                                editMode={editMode}
                                onEdit={onEdit}
                                onCreate={onCreate}
                                canEditNode={canEditNode}
                                maxLevels={maxLevels}
                                {...props}
                            />
                        )}
                    </Tree>
                </div>
            </div>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.items === nextProps.items &&
            prevProps.edit === nextProps.edit &&
            prevProps.selectedId === nextProps.selectedId
        );
    }
);

const Item = ({ node, dragHandle, renderIcon, editMode, onEdit, onCreate, maxLevels, canEditNode }) => {
    const icon = useMemo(() => renderIcon?.(node), [renderIcon, node]);
    return (
        <div
            title={node.data.name}
            className={classNames(styles.item, {
                [styles["level" + node.level]]: true,
                [styles.isLeaf]: node.isLeaf,
                [styles.isSelected]: node.isSelected,
                [styles.isOpen]: node.isOpen,
                [styles.isDragging]: node.isDragging
            })}
            ref={dragHandle}
            onClick={() => node.toggle()}
        >
            <div className={styles.spacers}>
                {Array.from({ length: node.level }, (_, i) => (
                    <div key={i} className={styles.spacer} />
                ))}
            </div>
            <div className={styles.wrapper}>
                {/* Caret */}
                {!node.isLeaf && !renderIcon ? (
                    <div className={styles.caret}>
                        <Icon name={node.isOpen ? "caret-down" : "caret-right"} />
                    </div>
                ) : null}

                {/* Icon */}
                {icon ? <div className={styles.icon}>{icon}</div> : null}

                {node.data.code && <div className={styles.code}>{node.data.code}.</div>}
                <div className={styles.label}>{node.data.name}</div>
                {editMode && (!canEditNode || canEditNode(node)) ? (
                    <div className={styles.actions}>
                        <ButtonGroup>
                            {node.level < maxLevels - 1 && (
                                <Button size="small" square icon="plus-small" onClick={() => onCreate(node)} />
                            )}
                            <Button size="small" square icon="edit" onClick={() => onEdit(node)} />
                        </ButtonGroup>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const Adder = ({ onClick }) => {
    return (
        <Clickable className={styles.adder} onClick={onClick}>
            <div className={styles.wrapper}>
                <Icon name="plus-small" />
            </div>
        </Clickable>
    );
};
