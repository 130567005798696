import React, { useContext } from "react";

import { OrganizationContext } from "../../../routes/organization/OrganizationRoot";
import { TrialExpiredMessage } from "../../messages/TrialExpiredMessage";
import { Page } from "../../page/Page";

export const RestrictedOrgRoute = ({ hasValidSubscription, element }) => {
    const { org, orgAccount } = useContext(OrganizationContext);

    if (!orgAccount) {
        return null;
    } else if (!hasValidSubscription || orgAccount.isActive) {
        return element;
    } else {
        return (
            <Page>
                <TrialExpiredMessage accountName={org.name} continueLink={`/me/help`} />
            </Page>
        );
    }
};
