import React from "react";
import { Clickable } from "../../button/Clickable";
import style from "./style.scss";

export const SidebarHeading = ({ link, title, description }) => {
    return (
        <Clickable className={style.base} link={link}>
            <div className={style.details}>
                <div className={style.title}>{title}</div>
                {description ? <div className={style.description}>{description}</div> : null}
            </div>
        </Clickable>
    );
};
